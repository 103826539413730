@import '~vuetify/src/styles/styles.sass';



















































































































































































































































































// @import '@design';
.message {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
